<template>
<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M52.4116 81.3909H43.9564V88.1778H52.4116V81.3909ZM42.3447 79.7793V89.7894H54.0232V79.7793H42.3447Z" fill="#BF001F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.4916 81.3909H55.0365V88.1778H63.4916V81.3909ZM53.4248 79.7793V89.7894H65.1033V79.7793H53.4248Z" fill="#BF001F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M57.3359 71.5427H48.8807V78.3295H57.3359V71.5427ZM47.269 69.931V79.9412H58.9475V69.931H47.269Z" fill="#BF001F"/>
<path d="M29.1035 56.3743L60.0001 31.3103L90.8966 56.3743M37.5298 89.7931V49.3115M82.4703 89.7931V49.6825" stroke="#212121" stroke-width="2"/>
</svg>
</template>

<script>
export default {
  name: 'IndoorSvg'
}
</script>
