<template>
<svg width="76" height="76" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.695 39.4675C24.695 41.4217 26.4782 43 28.5855 43C30.6928 43 32.3948 41.3465 32.3948 39.3924C32.3948 37.4382 30.6117 35.8599 28.5044 35.8599C26.3971 35.8599 24.614 37.4382 24.695 39.4675ZM28.5044 41.121C27.4508 41.121 26.7213 40.3694 26.7213 39.4675C26.7213 38.5656 27.5318 37.814 28.5044 37.814C29.477 37.814 30.2875 38.5656 30.2875 39.4675C30.2875 40.3694 29.477 41.121 28.5044 41.121Z" fill="#212121"/>
<path d="M2 39.4675C2 41.4217 3.78311 43 5.89042 43C7.99773 43 9.69979 41.3465 9.69979 39.3924C9.69979 37.4382 7.91668 35.8599 5.80937 35.8599C3.70206 35.8599 2 37.4382 2 39.4675ZM5.80937 41.121C4.83677 41.121 4.02626 40.3694 4.02626 39.4675C4.02626 38.5656 4.83677 37.814 5.80937 37.814C6.78198 37.814 7.59248 38.5656 7.59248 39.4675C7.59248 40.3694 6.78198 41.121 5.80937 41.121Z" fill="#212121"/>
<path d="M33.2969 37.7276V37.427V36.9008V14.1275V13.8269V13.7517V13.6014V13.0002H33.9453H34.3505H34.6747V13.6766V13.8269V14.353V36.7599H48.9342V38.2217H33.2969V37.7276Z" fill="#212121"/>
<path d="M4.02637 35.4087C4.59372 35.2584 5.16107 35.108 5.80948 35.108C8.40309 35.108 10.5104 37.0622 10.5104 39.4673C10.5104 39.7679 10.5104 40.0686 10.4293 40.3692H23.8837C23.8027 40.0686 23.8027 39.7679 23.8027 39.4673C23.8027 38.1896 24.37 36.987 25.4237 36.1603C27.4499 34.5819 30.3678 34.8074 32.0698 36.6112V14.9653L8.80834 14.9653C7.91679 18.0469 4.26952 30.824 4.26952 30.824V32.3272L4.02637 32.5526V35.4087ZM29.0709 15.8672H29.8815V33.9055H29.0709V15.8672ZM28.0983 31.7259L22.4248 15.8672H28.0983V31.7259ZM8.80834 28.6443C8.80834 28.6443 10.7536 17.8214 11.0778 15.9424H20.9659C21.2091 15.8672 21.3712 16.0176 21.4522 16.1679L25.5313 27.6651L27.612 33.5297L22.8301 33.3042L9.862 32.0265C9.862 32.0265 9.45675 32.0265 9.29464 32.0265H9.21359L9.3757 29.7717L8.80834 28.6443ZM7.83574 31.1246C7.75469 31.0494 7.51153 30.824 7.43048 30.6736C7.83574 28.4189 10.0241 16.0176 10.0241 15.8672H10.5104C10.1051 18.122 8.48414 27.4418 7.83574 31.1246Z" fill="#212121"/>
<path d="M31.5902 16.5595C30.4782 15.5429 29.9697 16.5591 29.9697 18.0709C29.9697 18.0709 30.3831 19.6099 30.9867 19.6099C31.5902 19.6099 32.0036 18.3387 31.5902 16.5595Z" fill="#BF001F" fill-opacity="0.56"/>
<path d="M29.9697 18.0709C29.9697 19.5827 36.3257 42.7455 36.3257 42.7455H54.7589L31.5902 16.5595C32.0036 18.3387 31.5902 19.6099 30.9867 19.6099C30.3831 19.6099 29.9697 18.0709 29.9697 18.0709Z" fill="#BF001F" fill-opacity="0.56"/>
<path d="M31.5902 16.5595C30.4782 15.5429 29.9697 16.5591 29.9697 18.0709C29.9697 18.0709 30.3831 19.6099 30.9867 19.6099C31.5902 19.6099 32.0036 18.3387 31.5902 16.5595Z" fill="#BF001F"/>
<path d="M36.3262 42.7451H54.7594" stroke="#BF001F"/>
</svg>
</template>

<script>
export default {
  name: 'LightPackageSvg'
}
</script>
