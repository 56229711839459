<template>
<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M85 90C85 92.2091 83.2091 94 81 94H40C37.7909 94 36 92.2091 36 90V41C36 37.134 39.134 34 43 34H62.3344C63.61 34 64.8092 34.6085 65.5625 35.638L84.2282 61.1486C84.7297 61.834 85 62.6613 85 63.5106V90Z" stroke="#212121" stroke-width="2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M73 36.5H65L63 33.5H73C79.9036 33.5 85.5 39.0964 85.5 46V62L82.5 61V46C82.5 40.7533 78.2467 36.5 73 36.5Z" fill="#212121"/>
<rect x="41" y="30" width="14" height="5" fill="#212121"/>
<rect x="37.7441" y="25" width="21.0366" height="5.4878" fill="#212121"/>
<path d="M69 74.4627C69 79.1777 65.1944 83 60.5 83C55.8056 83 52 79.1777 52 74.4627C52 73.9326 52.0481 73.4138 52.1402 72.9104L60.5 57L68.8598 72.9104C68.9519 73.4138 69 73.9326 69 74.4627Z" fill="#BF001F"/>
</svg>
</template>

<script>
export default {
  name: 'DiselSvg'
}
</script>
