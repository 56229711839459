<template>
  <section class="contact">
    <Header class="header" />
    <div class="contact-container">
      <template v-if="!contactSent">
        <div class="contact-image-container">
          <h2 class="contact-title">{{ $t(`contact.headline.${title || 'contactClassic'}`) }}</h2>
          <div class="contact-model-data">
            <p>{{ selectedModel.modelFullName }}<p>
            <p>
              {{ $n(totalPrice) }}
              {{ selectedModel.priceCurrency }}
              <span class="lwr" v-if="selectedModel.priceType == 'mtl'">{{ $t('price.monthly') }}</span>*
            </p>
            <span>
              {{ selectedModel.configurationType === 'GYR'
                ? $t('summary.priceDescription')
                : $t('summary.priceAboDescription') }}
            </span>
            <span>{{ $t('summary.asterisk') }}</span>
          </div>
          <div v-if="title == 'contactTrial'" class="contact-additional-text">
            <p v-html="$t('contact.additionalText')" class="inline">phone</p>
            <a href="tel:+49821/780002420" @click="sendPhoneAnalytics">
              0821 78 000 2420
            </a>.
          </div>
          <SvgPicker name="contactImage" class="contact-image" />
        </div>
        <div class="contact-form">
          <ContactForm />
        </div>
      </template>
      <ThankYou v-if="contactSent" />
    </div>
  </section>
</template>

<script>
import Header from './Header'
import ContactForm from './questions/ContactForm'
import SvgPicker from './svg/SvgPicker'
import ThankYou from './ThankYou'
import Analytics from '@/util/googleAnalytics'

export default {
  name: 'Contact',
  components: {
    ContactForm,
    Header,
    SvgPicker,
    ThankYou
  },
  computed: {
    title () {
      return this.$store.state.contactFormTitle
    },
    selectedModel () {
      return this.$store.state.selectedModel
    },
    totalPrice () {
      return this.$store.state.totalPrice
    },
    contactSent () {
      return this.$store.state.contactSent
    }
  },
  methods: {
    sendPhoneAnalytics () {
      Analytics.sendEvent({
        event: 'Phone number'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    flex-shrink: 0;
  }
  .inline {
    display: inline;
  }
  .contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &-container {
      align-self: center;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1440px;
      padding: 40px 80px;

      @media (max-width: 1600px) {
        padding: 36px 16px;
      }

      @media (max-width: 767px) {
        flex-grow: 1;
        min-width: 100%;
        flex-direction: column;
        padding: 36px 16px;
      }
    }

    &-title {
      margin-bottom: 16px;
      font-size: 36px;

      @media (max-width: 991px) {
        font-size: 28px;
      }

      @media (max-width: 767px) {
        margin-bottom: 24px;
        font-size: 22px;
      }
    }

    &-model-data {
      padding: 16px;
      border: 1px solid #cacaca;
      border-radius: 4px;

      & > p {
        margin: 0;

        & + p {
          margin-top: 16px;
        }
      }

      & > span {
        display: block;
        color: #666;
        font-size: 12px;
        text-transform: none;
      }
    }

    &-additional-text {
      margin-top: 16px;
      margin-bottom: 0;
      text-transform: none;
      font-weight: 400;

      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }

    &-image-container {
      flex: 1 1 50%;
      max-width: 472px;

      @media (max-width: 1600px) {
        max-width: 40%;
        margin-top: 16px;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        max-width: 100%;
      }
    }

    &-image {
      margin-top: 100px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &-form {
      flex: 1 1 50%;
      padding-left: 100px;
      max-width: 650px;

      @media (max-width: 1600px) {
        padding-left: 40px;
      }

      @media (max-width: 1250px) {
        max-width: 60%;
      }

      @media (max-width: 767px) {
        max-width: 100%;
        padding-left: 0;
      }
    }
  }
</style>
