<template>
  <section class="input-group-wrapper">
    <SvgPicker name="configuratorImage" class="bg-image" />
    <div class="input-group">
      <h1 class="input-group-header">{{ $t("environmentType.name") }}</h1>
      <h2 class="input-group-title">{{ $t("environmentType.driveType.title") }}?</h2>
      <h2 class="input-group-span">Mehrfachauswahl ist möglich</h2>
      <Select
        category="environmentType"
        question="driveType"
        :options="[{ id: 6, value: 'answer1' }, { id: 7, value: 'answer2' }]" />
    </div>
  </section>
</template>

<script>
import Select from './types/Select'
import SvgPicker from '../svg/SvgPicker'

export default {
  name: 'EnvironmentType',
  description: 'Environment',
  components: {
    Select,
    SvgPicker
  }
}
</script>

<style lang="scss" scoped>
.bg-image {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80%;
  height: 80%;
  max-width: 500px;
  max-height: 480px;
  overflow: hidden;

  @media (max-width: 767px) {
    display: none;
  }
}
</style>
