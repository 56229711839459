<template>
<svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.4328 94.6711C63.4328 99.5196 67.7881 103.436 72.9354 103.436C78.0827 103.436 82.2401 99.3331 82.2401 94.4846C82.2401 89.6361 77.8847 85.72 72.7374 85.72C67.5902 85.72 63.2348 89.6361 63.4328 94.6711ZM72.7374 98.7737C70.1638 98.7737 68.3821 96.9089 68.3821 94.6711C68.3821 92.4333 70.3618 90.5685 72.7374 90.5685C75.1131 90.5685 77.0928 92.4333 77.0928 94.6711C77.0928 96.9089 75.1131 98.7737 72.7374 98.7737Z" fill="#3E3F43"/>
<path d="M8 94.6711C8 99.5196 12.3554 103.436 17.5026 103.436C22.6499 103.436 26.8073 99.3331 26.8073 94.4846C26.8073 89.6361 22.4519 85.72 17.3047 85.72C12.1574 85.72 8 89.6361 8 94.6711ZM17.3047 98.7737C14.929 98.7737 12.9493 96.9089 12.9493 94.6711C12.9493 92.4333 14.929 90.5685 17.3047 90.5685C19.6803 90.5685 21.6601 92.4333 21.6601 94.6711C21.6601 96.9089 19.6803 98.7737 17.3047 98.7737Z" fill="#3E3F43"/>
<path d="M84.4424 90.3521V89.6061V88.3008V31.7968V31.0509V30.8644V30.4915V28.9996H86.0262H87.016H87.8079V30.6779V31.0509V32.3563V88.3008V89.6061V90.3521H84.4424Z" fill="#3E3F43"/>
<path d="M12.9492 84.5996C14.335 84.2266 15.7208 83.8537 17.3046 83.8537C23.6397 83.8537 28.787 88.7022 28.787 94.6696C28.787 95.4155 28.787 96.1615 28.589 96.9074H61.4523C61.2543 96.1615 61.2543 95.4155 61.2543 94.6696C61.2543 91.4994 62.6401 88.5157 65.2138 86.4644C70.1631 82.5483 77.29 83.1077 81.4474 87.5833V33.8766L24.6296 33.8766C22.4519 41.5223 13.5431 73.2242 13.5431 73.2242V76.9539L12.9492 77.5133V84.5996ZM74.1225 36.1144H76.1022V80.87H74.1225V36.1144ZM71.7468 75.462L57.8888 36.1144H71.7468V75.462ZM24.6296 67.8162C24.6296 67.8162 29.3809 40.9629 30.1728 36.3008H54.3253C54.9192 36.1144 55.3152 36.4873 55.5131 36.8603L70.559 79.9376L58.8787 79.3781L27.2032 76.2079C27.2032 76.2079 26.2133 76.2079 25.8174 76.2079H25.6194L26.0154 70.6135L24.6296 67.8162ZM22.2539 73.9701C22.0559 73.7837 21.462 73.2242 21.264 72.8513C22.2539 67.2568 27.5991 36.4873 27.5991 36.1144H28.787C27.7971 41.7088 23.8377 64.8325 22.2539 73.9701Z" fill="#3E3F43"/>
<rect width="38.206" height="3.63866" transform="matrix(1 0 0 -1 84.4424 91.5914)" fill="#3E3F43"/>
<path d="M110.837 50.196V84.9362" stroke="#CC0033" stroke-width="5" stroke-miterlimit="10"/>
<path d="M98.0571 50.6046L110.529 29L123 50.6046H98.0571Z" fill="#CC0033"/>
</svg>
</template>

<script>
export default {
  name: 'ArrowSvg'
}
</script>
