<template>
  <section class="input-group-wrapper">
    <SvgPicker name="configuratorImage" class="bg-image" />
    <div class="input-group">
      <h1 class="input-group-header">{{ $t("deliveryFuel.name") }}</h1>
      <h2 class="input-group-title">{{ $t("deliveryFuel.fuelType.title") }}?</h2>
      <h2 class="input-group-span">Mehrfachauswahl ist möglich</h2>
      <Select
        category="deliveryFuel"
        question="fuelType"
        :options="[{ id: 18, value: 'answer1' },{ id: 19, value: 'answer2' },{ id: 20, value: 'answer3' },{ id: 21, value: 'answer4' }]"/>
    </div>
  </section>
</template>

<script>
import Select from './types/Select'
import SvgPicker from '../svg/SvgPicker'

export default {
  name: 'DeliveryFuel',
  description: 'Delivery',
  components: {
    Select,
    SvgPicker
  }
}
</script>
