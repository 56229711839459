<template>
  <section id="app">
    <router-view/>
  </section>
</template>

<script>
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import Analytics from '@/util/googleAnalytics'

Vue.use(Vue2TouchEvents)

export default {
  name: 'App',
  mounted () {
    Analytics.initAnalyticsScripts()
  }
}
</script>

<style lang="scss">
@import './assets/scss/global.scss';
</style>
