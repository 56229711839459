<template>
  <section class="input-group-wrapper">
    <SvgPicker name="configuratorImage" class="bg-image" />
    <div class="input-group">
      <h1 class="input-group-header">{{ $t("deliveryForks.name") }}</h1>
      <h2 class="input-group-title">{{ $t("deliveryForks.forksLength.title") }}<ModalTrigger name="forksLength" :hasImage="true" /></h2>
      <Range
        category="deliveryForks"
        question="forksLength"
        :id="13"
        :min="0"
        :max="2400"
        :step="100"
        unit="mm"
      />
    </div>
  </section>
</template>

<script>
import Range from './types/Range'
import ModalTrigger from './ModalTrigger'
import SvgPicker from '../svg/SvgPicker'

export default {
  name: 'DeliveryForks',
  description: 'Delivery',
  components: {
    Range,
    ModalTrigger,
    SvgPicker
  }
}
</script>
