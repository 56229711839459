<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.125 4.875H3.25V7.45062L9.27063 13.4794L9.75 13.9506V21.125H13V19.5H14.625V21.125C14.625 21.556 14.4538 21.9693 14.149 22.274C13.8443 22.5788 13.431 22.75 13 22.75H9.75C9.31902 22.75 8.9057 22.5788 8.60095 22.274C8.29621 21.9693 8.125 21.556 8.125 21.125V14.625L2.10438 8.60437C1.9523 8.4532 1.83165 8.27343 1.74938 8.07541C1.6671 7.87739 1.62483 7.66505 1.625 7.45062V4.875C1.625 4.44402 1.79621 4.0307 2.10095 3.72595C2.4057 3.4212 2.81902 3.25 3.25 3.25H21.125V4.875Z" fill="white"/>
    <path d="M24.1394 9.17295L21.7019 6.73545C21.6263 6.6593 21.5365 6.59885 21.4375 6.5576C21.3385 6.51635 21.2323 6.49512 21.125 6.49512C21.0177 6.49512 20.9115 6.51635 20.8125 6.5576C20.7135 6.59885 20.6237 6.6593 20.5481 6.73545L13 14.2917V17.8748H16.5831L24.1394 10.3267C24.2155 10.2512 24.276 10.1613 24.3172 10.0623C24.3585 9.96329 24.3797 9.85709 24.3797 9.74983C24.3797 9.64257 24.3585 9.53637 24.3172 9.43736C24.276 9.33835 24.2155 9.24849 24.1394 9.17295ZM15.9169 16.2498H14.625V14.958L18.6875 10.8955L19.9794 12.1873L15.9169 16.2498ZM21.125 11.0417L19.8331 9.74983L21.125 8.45795L22.4169 9.74983L21.125 11.0417Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'ConfSvg'
}
</script>
