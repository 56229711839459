<template>
  <div class="equipment-wrapper">
    <h3 class="equipment-title" v-if="equipment.length">{{ $t('equipment.title') }}</h3>
    <div class="equipment-container">
      <div v-for="item in equipment" :key="item.name" class="equipment-group">
        <div class="equipment-group-image">
          <SvgPicker :name="item.name" />
        </div>
        <div class="equipment-group-text">
          <span class="equipment-group-name">{{ $t(`equipment.${item.name}.title`) }}</span>
          <span class="equipment-group-price">
            {{ $n(item.price.toFixed(0)) }}
            {{ selectedModel.priceCurrency }}
            <span class="lwr" v-if="selectedModel.priceType == 'mtl'">{{ $t('price.monthly') }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgPicker from './svg/SvgPicker'

export default {
  name: 'SummarySelectedEquipment',
  components: {
    SvgPicker
  },
  computed: {
    answers () {
      return this.$store.state.answers
    },
    selectedModel () {
      return this.$store.state.selectedModel
    },
    equipment () {
      return this.$store.state.equipment
    }
  }
}
</script>>

<style lang="scss" scoped>
  // There are overriders forcing PDF to render desktop
  .equipment {
    &-wrapper {
      margin: auto;
    }

    &-title {
      margin-bottom: 8px;
      font-size: 16px;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
    }

    &-group {
      display: flex;
      width: 25%;
    }

    &-group-image {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      margin-right: 8px;
      padding: 4px;
      border: 1px solid #cacaca;
      border-radius: 4px;

      .icon {
        width: 100%;
        height: 100%;
      }
    }

    &-group-name {
      display: block;
      margin-bottom: 8px;
      font-weight: 400;
    }

    &-group-text {
      margin-bottom: 0;
      margin-right: 8px;
      font-size: 14px;
    }

    &-group-price {
      display: block;
    }
  }
</style>
