<template>
  <div>
    <div class="radio-container">
      <label class="radio" v-for="option in options" :key="option.value">
        <input type="radio"
          :name="name"
          :value="option.value"
          :checked="value === option.value"
          :required="required"
          @change="onChange"
        >
        <span class="radio-icon"></span>
        <span class="radio-label">{{ option.text }}</span>
      </label>
    </div>
    <div class="input-errors" v-if="errors.length">
      <span v-for="error in errors" :key="error">{{ $t(`error.${error}`) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  props: ['name', 'options', 'required', 'value'],
  data: function () {
    return {
      selected: this.$props.value,
      hasError: false,
      errors: []
    }
  },
  methods: {
    onChange (e) {
      const value = e.target.value
      this.selected = true
      this.validate()
      this.$emit('update', value)
    },
    validate () {
      this.errors = []
      if (this.$props.required && !this.selected) {
        this.errors.push('required')
        this.$emit('error')
      }
    }
  }
}
</script>
