<template>
  <div class="radio-container">
    <label class="radio" v-for="(option, index) in options" :key="option.id">
      <input type="radio" :name="question" :value="option.value" :checked="option.value === answer.value || selectedIndex === index" v-on:change="setAnswer(option)">
      <span class="radio-icon"></span>
      <span class="radio-label">{{ $t(`${category}.${question}.${option.value}`) }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioInput',
  props: ['category', 'question', 'options', 'selectedIndex'],
  computed: {
    answer () {
      return this.$store.state.answers[this.category].data[this.question][0] || { id: null, value: null }
    }
  },
  methods: {
    setAnswer (answer) {
      if (answer.id === 0) {
        this.$emit('returnAction', true)
        return false
      }
      this.$emit('returnAction', false)
      const data = {
        category: this.category,
        question: this.question,
        answer: [answer]
      }
      this.$store.dispatch('setAnswer', data)
    }
  }
}
</script>
