<template>
  <img :src="url" :alt="alt" class="img" />
</template>

<script>
export default {
  name: 'GrmImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      url: require('../../../assets/img/no-image.png'),
      loading: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.url = this.src
      this.loading = false
    }, 0)
  }
}
</script>

<style lang="scss" scoped>
  .img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    transition: all 0.25s;
  }
</style>
