<template>
  <div class="pdf-wrapper" ref="pdfTemplate">
    <div class="pdf-container">
      <div class="pdf-header">
        <p class="pdf-contact-type">{{ $t(`pdf.headline.${contactFormTitle}`) }}</p>
        <img class="pdf-logo" src="../../assets/img/gruma-logo.jpg" alt="Gruma">
      </div>
      <hr>
      <div class="pdf-body">
        <Summary />
        <SummarySelectedInput />
        <SummarySelectedEquipment />
      </div>
      <div class="pdf-footer">
        <div class="pdf-footer-section">
          <span>GRUMA Nutzfahrzeuge GmbH</span>
          <span>Äußere Industriestraße 22</span>
          <span>86316 Friedberg</span>
          <span>www.gruma.de</span>
        </div>
        <div class="pdf-footer-section">
          <span class="blank"></span>
          <span>+49 821 780 00 2791</span>
          <span>kontakt@gruma.de</span>
        </div>
        <div class="pdf-footer-section">
          <span>Ust-IdNr. DE 127 481 508</span>
          <span>HRB Augsburg Nr. 1119</span>
          <span>Geschäftsführer</span>
          <span>Hans Gruber, Edwin Würstle</span>
        </div>
        <div class="pdf-footer-section">
          <span>Commerzbank AG </span>
          <span>IBAN  DE10 7208 0001 0101 7172 00</span>
          <span>BIC DRESDEFF720</span>
          <span>Stadtsparkasse Augsburg</span>
          <span>IBAN  DE68 7205 0000 0000 2002 38</span>
          <span>BIC AUGS DE77 XXX</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
import Summary from '../questions/Summary'
import SummarySelectedInput from '../SummarySelectedInput'
import SummarySelectedEquipment from '../SummarySelectedEquipment'

export default {
  name: 'PdfTemplate',
  components: {
    Summary,
    SummarySelectedInput,
    SummarySelectedEquipment
  },
  data: function () {
    return {
      options: {
        margin: 0.8,
        filename: 'file.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: false,
        html2canvas: {
          scale: 2,
          useCORS: true,
          allowTaint: true
        },
        jsPDF: {
          unit: 'cm',
          format: 'a4',
          orientation: 'portrait'
        }
      }
    }
  },
  computed: {
    answers () {
      return this.$store.state.answers
    },
    equipment () {
      return this.$store.state.equipment
    },
    selectedModel () {
      return this.$store.state.selectedModel
    },
    contactForm () {
      return this.$store.state.contactForm
    },
    contactFormTitle () {
      return this.$store.state.contactFormTitle
    }
  },
  methods: {
    async generateFile () {
      const template = this.$refs.pdfTemplate
      const options = this.options
      const pdf = html2pdf().set(options).from(template)
      // const pdf = html2pdf().set(options).from(template).outputPdf().setFont('Roboto', 'normal', 400)
      const file = await pdf.output('blob')
      // return file
      this.$emit('generated', file)
    }
  },
  mounted () {
    const file = this.generateFile()
    this.contactForm.file = new File([file], 'file.pdf', { type: file.type })
  }
}
</script>

<style lang="scss" scoped>
.pdf-wrapper {
  font-family: Roboto, sans-serif;
}

.pdf-container {
  position: relative;
  height: 277mm;
}

.pdf-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pdf-body {
  position: relative;
}

.pdf-contact-type {
}

.pdf-reference-number {
  font-size: 28px;
  font-weight: 700;
}

.pdf-logo {
  display: block;
  max-height: 60px;
}

.pdf-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.pdf-specification {
  display: flex;
  margin-bottom: 24px;
  padding-top: 16px;
  border-top: 1px solid #cacaca;

  &-group {
    flex: 1 0 25%;
    padding: 0 8px;
  }

  &-name {
    margin-bottom: 8px;
  }

  &-item {
    margin-bottom: 4px;
    span {
      display: block;
    }
  }
}

.pdf-model {
  &-container {
    display: flex;
    align-items: stretch;
    width: 100%;
    margin-bottom: 24px;
    padding-top: 16px;
    border-top: 1px solid #cacaca;
  }

  &-image {
    flex: 1 1 50%;
    width: 50%;

    img {
      display: block;
      width: 100%;
    }
  }

  &-details {
    flex: 1 1 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }

  &-info {
    flex: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #cacaca;
  }

  &-specification {
    flex: 1;
  }
}

.pdf-equipment {
  margin-bottom: 24px;
  padding-top: 16px;
  border-top: 1px solid #cacaca;

  &-container {
    display: flex;
  }

  &-title {
    margin-bottom: 16px;
  }

  &-item {
    margin-right: 16px;
  }

  &-image {
    img {
      display: block;
      width: 100%;
    }
  }

  &-name {
    margin: 8 0 0 0;
  }
}

.pdf-other-models {
  display: block;
  padding-top: 16px;
  border-top: 1px solid #cacaca;
}

.pdf-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #cacaca;

  &-section {
    flex: 1 1 25%;
  }

  span {
    display: block;
    font-size: 11px;
    line-height: 13px;

    &.blank {
      height: 13px;
    }
  }
}
</style>

<style lang="scss">
.pdf-body {
  .mobile-controls {
    display: none !important;
  }

  .headline {
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }

    div:last-child {
      @media (max-width: 767px) {
        text-align: right;
        margin-top: 0;
      }
    }
  }

  .title {
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  .sub-text {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .model {
    &-container {
      @media (max-width: 767px) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &-image {
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    &-details {
      @media (max-width: 767px) {
        margin-left: 36px;
      }
    }
  }
}
</style>
