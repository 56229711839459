<template>
<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.5195 63.5879H46.252V65H40.7617V56.4688H42.5195V63.5879ZM49.1348 65H47.377V56.4688H49.1348V65ZM53.9512 62.0293H50.6523V60.6641H53.9512V62.0293ZM57.291 65H55.5332V56.4688H57.291V65ZM65.9336 60.9277C65.9336 61.7676 65.7852 62.5039 65.4883 63.1367C65.1914 63.7695 64.7656 64.2578 64.2109 64.6016C63.6602 64.9453 63.0273 65.1172 62.3125 65.1172C61.6055 65.1172 60.9746 64.9473 60.4199 64.6074C59.8652 64.2676 59.4355 63.7832 59.1309 63.1543C58.8262 62.5215 58.6719 61.7949 58.668 60.9746V60.5527C58.668 59.7129 58.8184 58.9746 59.1191 58.3379C59.4238 57.6973 59.8516 57.207 60.4023 56.8672C60.957 56.5234 61.5898 56.3516 62.3008 56.3516C63.0117 56.3516 63.6426 56.5234 64.1934 56.8672C64.748 57.207 65.1758 57.6973 65.4766 58.3379C65.7812 58.9746 65.9336 59.7109 65.9336 60.5469V60.9277ZM64.1523 60.541C64.1523 59.6465 63.9922 58.9668 63.6719 58.502C63.3516 58.0371 62.8945 57.8047 62.3008 57.8047C61.7109 57.8047 61.2559 58.0352 60.9355 58.4961C60.6152 58.9531 60.4531 59.625 60.4492 60.5117V60.9277C60.4492 61.7988 60.6094 62.4746 60.9297 62.9551C61.25 63.4355 61.7109 63.6758 62.3125 63.6758C62.9023 63.6758 63.3555 63.4453 63.6719 62.9844C63.9883 62.5195 64.1484 61.8438 64.1523 60.957V60.541ZM74.1484 65H72.3906L68.9688 59.3867V65H67.2109V56.4688H68.9688L72.3965 62.0938V56.4688H74.1484V65Z" fill="#BF001F"/>
<path d="M29 48C29 45.2386 31.2386 43 34 43H38.5H42.75H47H81C83.7614 43 86 45.2386 86 48V73C86 75.7614 83.7614 78 81 78H34C31.2386 78 29 75.7614 29 73V48Z" stroke="#212121" stroke-width="2"/>
<rect x="87" y="54" width="6" height="14" fill="#212121"/>
</svg>
</template>

<script>
export default {
  name: 'LithBatterySvg'
}
</script>
