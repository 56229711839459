<template>
  <section class="app">
    <Header />
    <Navigation />
    <Overview />
    <Questions />
    <OverviewControls />
    <Modal />
  </section>
</template>

<script>
import Header from './Header.vue'
import Navigation from './Navigation.vue'
import Overview from './Overview.vue'
import Questions from './Questions.vue'
import OverviewControls from './OverviewControls.vue'
import Modal from './Modal.vue'

export default {
  name: 'App',
  components: {
    Header,
    Navigation,
    Overview,
    Questions,
    OverviewControls,
    Modal
  },
  computed: {
    referenceNumber () {
      return this.$store.state.referenceNumber
    }
  },
  methods: {
    appHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
  },
  unmounted () {
    window.removeEventListener('resize', this.appHeight)
  }
}
</script>
