<template>
<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60.5 72C51.3705 69.9459 47.157 64.3784 60.5 50C73.843 64.3784 69.6295 69.9459 60.5 72Z" fill="#BF001F"/>
<path d="M69 30C73.9706 30 78 34.0294 78 39L78 40.3898L78 45L78 49.6102L78 83C78 87.9706 73.9706 92 69 92L52 92C47.0294 92 43 87.9706 43 83L43 39C43 34.0294 47.0294 30 52 30L69 30Z" stroke="#212121" stroke-width="2"/>
<rect x="50" y="92" width="21" height="6" fill="#212121"/>
<path d="M51 27C51 24.2386 53.2386 22 56 22H65C67.7614 22 70 24.2386 70 27V30H51V27Z" stroke="#212121" stroke-width="2"/>
<rect x="59" y="27" width="4" height="3" fill="#212121"/>
<rect x="58" y="25" width="6" height="2" fill="#212121"/>
</svg>
</template>

<script>
export default {
  name: 'GasSvg'
}
</script>
