<template>
<svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M63.3237 94.3566C63.3237 99.1081 67.592 102.946 72.6363 102.946C77.6807 102.946 81.7549 98.9254 81.7549 94.1738C81.7549 89.4223 77.4866 85.5845 72.4423 85.5845C67.398 85.5845 63.1297 89.4223 63.3237 94.3566ZM72.4423 98.3771C69.9202 98.3771 68.1741 96.5496 68.1741 94.3566C68.1741 92.1635 70.1142 90.336 72.4423 90.336C74.7705 90.336 76.7106 92.1635 76.7106 94.3566C76.7106 96.5496 74.7705 98.3771 72.4423 98.3771Z" fill="#3E3F43"/>
<path d="M9 94.3566C9 99.1081 13.2683 102.946 18.3126 102.946C23.3569 102.946 27.4312 98.9254 27.4312 94.1738C27.4312 89.4223 23.1629 85.5845 18.1186 85.5845C13.0743 85.5845 9 89.4223 9 94.3566ZM18.1186 98.3771C15.7904 98.3771 13.8503 96.5496 13.8503 94.3566C13.8503 92.1635 15.7904 90.336 18.1186 90.336C20.4467 90.336 22.3869 92.1635 22.3869 94.3566C22.3869 96.5496 20.4467 98.3771 18.1186 98.3771Z" fill="#3E3F43"/>
<path d="M83.9136 90.1254V89.3944V88.1151V32.7412V32.0102V31.8275V31.462V30H85.4657H86.4357H87.2118V31.6447V32.0102V33.2895V88.1151V89.3944V90.1254H83.9136Z" fill="#3E3F43"/>
<path d="M13.8506 84.4877C15.2087 84.1222 16.5668 83.7567 18.1189 83.7567C24.3273 83.7567 29.3716 88.5083 29.3716 94.3563C29.3716 95.0873 29.3716 95.8183 29.1776 96.5494H61.3836C61.1896 95.8183 61.1896 95.0873 61.1896 94.3563C61.1896 91.2495 62.5477 88.3255 65.0698 86.3152C69.9201 82.4775 76.9046 83.0257 80.9788 87.4118V34.7792L25.2973 34.7792C23.1632 42.272 14.4326 73.3399 14.4326 73.3399V76.9949L13.8506 77.5431V84.4877ZM73.8004 36.9722H75.7405V80.8327H73.8004V36.9722ZM71.4722 75.5329L57.8914 36.9722H71.4722V75.5329ZM25.2973 68.04C25.2973 68.04 29.9536 41.7238 30.7297 37.155H54.3992C54.9812 36.9722 55.3692 37.3377 55.5632 37.7032L70.3082 79.9189L58.8614 79.3707L27.8195 76.2639C27.8195 76.2639 26.8494 76.2639 26.4614 76.2639H26.2674L26.6554 70.7813L25.2973 68.04ZM22.9692 74.0709C22.7752 73.8881 22.1931 73.3399 21.9991 72.9743C22.9692 67.4918 28.2075 37.3377 28.2075 36.9722H29.3716C28.4015 42.4548 24.5213 65.116 22.9692 74.0709Z" fill="#3E3F43"/>
<rect width="37.4419" height="3.56589" transform="matrix(1 0 0 -1 83.9136 91.339)" fill="#3E3F43"/>
<path d="M95.6108 55.8524H118.408L124 84.3795H90.124L95.6108 55.8524Z" fill="#CC0033"/>
<path d="M107.062 59.4184C109.524 59.4184 111.519 57.0236 111.519 54.0695C111.519 51.1155 109.524 48.7207 107.062 48.7207C104.6 48.7207 102.604 51.1155 102.604 54.0695C102.604 57.0236 104.6 59.4184 107.062 59.4184Z" stroke="#CC0033" stroke-width="2" stroke-miterlimit="10"/>
<path d="M101.753 72.9921V63.4126H102.749V68.8768L105.101 66.0552H106.388L104.14 68.629L106.609 72.9921H105.381L103.442 69.4411L102.737 70.2394V72.9921H101.753Z" fill="white"/>
<path d="M107.227 73.5713L108.193 73.7433C108.234 74.0943 108.345 74.3558 108.531 74.5141C108.776 74.7343 109.113 74.8375 109.544 74.8375C110.004 74.8375 110.36 74.7274 110.61 74.5141C110.86 74.2939 111.029 73.9911 111.117 73.5988C111.169 73.3579 111.192 72.8556 111.186 72.0917C110.75 72.6973 110.208 73.0001 109.562 73.0001C108.752 73.0001 108.129 72.656 107.687 71.9678C107.244 71.2796 107.023 70.4538 107.023 69.4903C107.023 68.8297 107.122 68.2172 107.326 67.6598C107.53 67.1023 107.821 66.6688 108.205 66.366C108.589 66.0632 109.044 65.9049 109.562 65.9049C110.255 65.9049 110.825 66.2352 111.274 66.8959V66.0632H112.194V72.0641C112.194 73.1446 112.101 73.9085 111.914 74.3627C111.728 74.8169 111.431 75.1678 111.029 75.4294C110.627 75.6909 110.127 75.8216 109.539 75.8216C108.84 75.8216 108.275 75.6358 107.838 75.2642C107.419 74.8788 107.209 74.3214 107.227 73.5713ZM108.048 69.4009C108.048 70.3093 108.199 70.9768 108.508 71.3966C108.816 71.8164 109.195 72.0228 109.661 72.0228C110.115 72.0228 110.499 71.8164 110.808 71.3966C111.117 70.9837 111.274 70.3299 111.274 69.4422C111.274 68.5957 111.117 67.9488 110.796 67.5221C110.476 67.0886 110.092 66.8752 109.643 66.8752C109.201 66.8752 108.822 67.0886 108.514 67.5153C108.205 67.9419 108.048 68.5613 108.048 69.4009Z" fill="white"/>
</g>
<defs>
</defs>
</svg>

</template>

<script>
export default {
  name: 'ModalBurdenWeightSvg'
}
</script>
