<template>
    <section class="recommendations-wrapper" :class="{ extended }">
      <div class="header">
          <h3 class="title">{{ $t('recommendations.name') }}</h3>
          <!-- <button class="btn btn--empty toggle-expand-cta" @click="toggleRecommendations">
            {{ extended ? $t('recommendations.showLess') : $t('recommendations.showMore') }}
          </button> -->
      </div>
      <div class="recommendations-container" :class="{ loading: isLoading, dragging }" ref="drawer" :style="{ height, transform }">
        <div class="toggle-expand-mobile-cta"
          v-touch:start="startDrag"
          v-touch:moving="drag"
          v-touch:end="stopDrag"
        ></div>
        <div class="content">
           <div class="recommendation" v-for="(item, index) in recommendations" :key="index" @click="selectModel(item)">
            <a href="#summary_block">
              <div class="image">
                <GrmImage :src="item.pictureLinkSmall" :alt="item.modelName" />
              </div>

            <p class="name">{{ item.modelName }}</p>
            <p class="price">
              {{ $n((+item.price).toFixed(0)) }}
              {{ item.priceCurrency }}
              {{ item.priceType == 'mtl' ? $t('price.monthly') : '' }}
            </p>
          </a>
          </div>
          <div class="recommendation" v-if="!recommendations.length" @click="setStep">
            <div class="image">
              <img src="../assets/img/no-recommendation.png" alt="No recommendation">
            </div>
            <p class="name">{{ $t('recommendations.noResult') }}</p>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import GrmImage from '@/components/questions/types/Image'
import Analytics from '@/util/googleAnalytics'

export default {
  name: 'Recommendations',
  components: {
    GrmImage
  },
  data: function () {
    return {
      dragStart: 0,
      dragStop: 0,
      height: window.innerWidth < 767 ? 'auto' : '',
      transform: window.innerWidth < 767 ? 'translateY(0)' : '',
      dragging: false
    }
  },
  computed: {
    activeStep () {
      return this.$store.state.activeStep
    },
    recommendations () {
      return this.$store.state.recommendations
    },
    extended () {
      return this.$store.state.recommendationsExpanded
    },
    isLoading () {
      return this.$store.state.recommendationsLoading
    }
  },
  methods: {
    toggleRecommendations () {
      if (!this.extended) {
        Analytics.sendEvent({
          event: 'Show me all results'
        })
      }
      this.$store.dispatch('toggleRecommendations')
    },
    openRecommendations (event) {
      this.$store.dispatch('toggleRecommendations', true)
    },
    closeRecommendations () {
      this.$store.dispatch('toggleRecommendations', false)
    },
    setStep () {
      this.$store.dispatch('setStep', 9)
    },
    nextStep () {
      this.$store.dispatch('nextStep')
    },
    prevStep () {
      this.$store.dispatch('prevStep')
    },
    selectModel (model) {
      Analytics.sendEvent({
        event: 'Model Selected',
        data: model.modelName
      })
      this.$store.dispatch('selectModel', { model, activeStep: this.activeStep })
    },
    startDrag (event) {
      this.dragStart = event.changedTouches[0].pageY
      this.dragging = true
      this.startHeight = this.height
    },
    drag (event) {
      if (this.dragStart === 0) {
        return false
      }
      const delta = event.changedTouches[0].pageY - this.dragStart
      this.height = this.extended ? `${window.innerHeight - delta}px` : `${156 - delta}px`
      this.transform = this.extended ? `translateY(calc(-100% + ${156 + delta}px))` : `translateY(${delta}px)`
      console.log('delta: ', delta)
    },
    stopDrag (event) {
      this.dragging = false
      const delta = this.dragStart - event.changedTouches[0].pageY
      if (delta < -60) {
        this.$store.dispatch('toggleRecommendations', false)
        document.documentElement.style.setProperty('--overscroll-behavior', 'auto')
      } else if (delta > 60) {
        this.$store.dispatch('toggleRecommendations', true)
        document.documentElement.style.setProperty('--overscroll-behavior', 'contain')
      }
      this.dragStart = 0
      this.transform = ''
      this.height = ''
    }
  }
}
</script>

<style scoped lang="scss">
  .recommendations-wrapper {
    margin-right: 20px;
    margin-bottom: 60px;
    background-color: #fff;
    z-index: 900;

    @media (max-width: 991px) {
      margin-left: 16px;
      margin-right: 0px;
    }

    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }

    &.extended {
      & + .controls {
        @media (max-width: 767px) {
          display: none;
        }
      }

      .recommendations-container {
        transition: height 0.6s, transform 0.2s;
      }

      .content {
        flex-wrap: wrap;
        height: calc(100% - 52px);
        margin: 0 0 0 -12px;

        @media (max-width: 767px) {
          height: calc(100% - 48px);
          margin: 0 -12px;
        }
      }

      .recommendation {
        @media (max-width: 767px) {
          width: calc(33.33% - 24px);
        }
      }
      .image {
        @media (max-width: 767px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .recommendations-container {
    width: 100%;
    transform: translateY(calc(0px));
    background-color: #fff;
    overflow: hidden;
    transition: height 0.4s, transform 0.6s 0.2s;

    &.dragging {
      transition: none !important;
      overflow: hidden !important;

      .content {
        opacity: 0;
      }
    }

    @media (max-width: 1600px) {
      height: auto;
    }

    @media (max-width: 767px) {
      border-radius: 16px 16px 0 0;
      transition: height 0.8s ease-out, transform 0.4s ease-out;
    }

    &.loading {
      &:before {
        content: ' ';
        position: absolute;
        top: 48px;
        left: 0;
        width: 100%;
        height: calc(100% - 48px);
        background-color: #fff;
      }

      &:after {
        $unit: 60px;
        content: '';
        position: absolute;
        top: calc(50% + 24px);
        left: 50%;
        width: $unit;
        height: $unit;
        border: 4px solid #cacaca;
        border-top-color: #BF001F;
        border-radius: 50%;
        animation: rotate 1s cubic-bezier(0.75, 0, 0.25, 1) infinite forwards;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;

    @media (max-width: 767px) {
      margin-bottom: 8px;
    }
  }

  .title {
    color: #212121;
    font-size: 22px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
    align-content: flex-start;
    margin: 0 -12px;
    overflow: auto;
    opacity: 1;
    transition: opacity 0.5s;
    @media (max-width: 767px) {
      justify-content: space-between;
      margin: 0;
    }

  }

  .recommendation {
    flex: 0 0 auto;
    width: 140px;
    margin: 0 12px 16px 12px;
    cursor: pointer;

    @media (max-width: 1600px) {
      width: 110px;
    }

    @media (max-width: 767px) {
      width: 70px;
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    padding: 8px;
    border: 1px solid #cacaca;
    border-radius: 4px;
    transition: padding 0.25s;

    @media (max-width: 1600px) {
      width: 110px;
      height: 110px;
    }

    @media (max-width: 767px) {
      padding: 0;
      width: 70px;
      height: 70px;
    }

    &:hover {
      padding: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .name {
    margin: 4px 0 0 0;
    color: #666;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 767px) {
      flex-shrink: 0;
      width: 100%;
      font-size: 10px;
      font-weight: 400;
      text-align: center;
    }
  }

  .price {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;

    @media (max-width: 767px) {
      flex-shrink: 0;
      width: 100%;
      font-size: 10px;
      font-weight: 400;
      text-align: center;
    }
  }

  .toggle-expand-cta {
    color: #BF001F;

    @media (max-width: 991px) {
      margin-right: 8px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .toggle-expand-mobile-cta {
    display: none;

    @media (max-width: 767px) {
      position: relative;
      display: none;
      width: 100%;
      margin: auto;
      padding: 16px 0 8px 0;
      border: none;
      cursor: pointer;

    //   &:before {
    //     content: ' ';
    //     display: block;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     width: 16px;
    //     height: 2px;
    //     margin: auto;
    //     background-color: #cacaca;
    //     pointer-events: none;
    //     transform: translate(-50%, -50%) rotate(-30deg);
    //     transform-origin: top right;
    //   }

    //   &:after {
    //     content: ' ';
    //     display: block;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     width: 16px;
    //     height: 2px;
    //     margin: auto;
    //     background-color: #cacaca;
    //     pointer-events: none;
    //     transform: translate(50%, -50%) rotate(30deg);
    //     transform-origin: top left;
    //   }

      &:after {
        content: ' ';
        display: block;
        width: 32px;
        margin: auto;
        height: 2px;
        background-color: #cacaca;
        pointer-events: none;
      }
    }
  }

  .controls {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 98px;
      right: 20px;
      z-index: 901;
    }
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: 0;
    margin-left: 16px;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 4px 8px #6B6A6B;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.prev {
      background-color: #666;
    }

    &.next {
      background-color: #BF001F;

      .icon{
        transform: rotate(180deg);
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
</style>
