var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('form',{staticClass:"contact-form",attrs:{"action":"","method":"post","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)},"change":_vm.updateForm}},[_c('Radio',{ref:"title",staticClass:"input-wrapper",attrs:{"value":_vm.contactForm.title,"name":"title","required":true,"options":[{
        value: 'mr',
        text: _vm.$t('contact.title.mr')
      }, {
        value: 'mrs',
        text: _vm.$t('contact.title.mrs')
      }]}}),_c('div',{staticClass:"contact-form-50"},[_c('Input',{ref:"company",attrs:{"value":_vm.contactForm.company,"name":"company","type":"text","label":_vm.$t('contact.companyName'),"placeholder":_vm.$t('contact.companyName'),"required":true},on:{"error":_vm.onError}}),_c('Input',{ref:"contactPerson",attrs:{"value":_vm.contactForm.contactPerson,"name":"fullName","type":"text","label":_vm.$t('contact.contactPerson'),"placeholder":_vm.$t('contact.contactPerson'),"required":true},on:{"error":_vm.onError}}),_c('Input',{ref:"mail",attrs:{"value":_vm.contactForm.mail,"name":"mail","type":"email","label":_vm.$t('contact.mail'),"placeholder":_vm.$t('contact.mail'),"required":true},on:{"error":_vm.onError}}),_c('Input',{ref:"phone",attrs:{"value":_vm.contactForm.phone,"name":"phone","type":"tel","label":_vm.$t('contact.phone'),"placeholder":_vm.$t('contact.phone'),"required":true},on:{"error":_vm.onError}})],1),_c('Input',{ref:"function",attrs:{"value":_vm.contactForm.function,"name":"function","type":"text","label":_vm.$t('contact.function'),"placeholder":_vm.$t('contact.function')},on:{"error":_vm.onError}}),_c('Textarea',{ref:"message",attrs:{"value":_vm.contactForm.message,"name":"message","label":_vm.$t('contact.message'),"placeholder":_vm.$t('contact.message')},on:{"error":_vm.onError}}),_vm._v(" "),_c('div',{staticClass:"input-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t('contact.referenceNumber'))+": #"+_vm._s(_vm.referenceNumber))])]),_c('div',{staticClass:"controls-wrapper"},[_c('span',{on:{"click":_vm.sendBackButtonAnalytics}},[_c('router-link',{staticClass:"btn btn--big",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t('contact.back')))])],1),_c('button',{staticClass:"btn btn--primary btn--big",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('contact.submit')))])])],1),_c('div',{staticClass:"pdf-holder"},[_c('PdfTemplate',{on:{"generated":_vm.prepareFile}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }