<template>
  <section class="input-group-wrapper">
    <SvgPicker name="configuratorImage" class="bg-image" />
    <div class="input-group">
      <h1 class="input-group-header">{{ $t("operationLowest.name") }}</h1>
      <h2 class="input-group-title">{{ $t("operationLowest.lowestPoint.title") }}?<ModalTrigger name="lowestPoint" :hasImage="true" /></h2>
      <Range
        category="operationLowest"
        question="lowestPoint"
        :id="10"
        :min="2"
        :max="5"
        :step="0.1"
        unit="m"
      />
    </div>
  </section>
</template>

<script>
import Range from './types/Range'
import ModalTrigger from './ModalTrigger'
import SvgPicker from '../svg/SvgPicker'

export default {
  name: 'OperationLowest',
  description: 'Operation',
  components: {
    Range,
    ModalTrigger,
    SvgPicker
  },
  computed: {
    showInput () {
      return this.$store.state.answers.operationLowest.data.widthRestriction[0]?.id === 16
    }
  },
  data: function () {
    return {
      preselectedIndex: null
    }
  },
  mounted () {
    this.preselectedIndex = this.showInput ? 0 : null
  },
  methods: {
    openInput () {
      const data = {
        category: 'operationLowest',
        question: 'widthRestriction',
        answer: [{ id: 16, value: '5 m' }]
      }
      this.$store.dispatch('setAnswer', data)
    }
  }
}
</script>
