<template>
  <section class="overview" :class="{ 'active': showConf }">
    <div class="overview-content">
      <SelectedInputs v-if="activeStep < 10" />
    </div>
    <div class="overview-controls">
      <button class="btn btn--big btn--max visible-xs" @click="closeConf()">{{ $t("configurator.back") }}</button>
    </div>
  </section>
</template>

<script>
import SelectedInputs from './SelectedInputs'

export default {
  name: 'Overview',
  components: {
    SelectedInputs
  },
  computed: {
    activeStep () {
      return this.$store.state.activeStep
    },
    showConf () {
      return this.$store.state.showConf
    }
  },
  methods: {
    closeConf () {
      this.$store.dispatch('toggleConf', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .overview {
    grid-area: overview;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    border-left: 1px solid #cacaca;
    background-color: #fff;
    overflow: hidden;
    border-right: 2px solid #8a8787;
    border-bottom-width: 20px;

    @media (max-width: 1600px) {
      padding: 40px 20px;
    }

    @media (max-width: 1250px) {
      padding: 12px 16px 16px 16px;
    }

    @media (max-width: 991px) {
      padding: 12px 16px 16px 16px;
    }

    @media (max-width: 767px) {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 48px 24px 24px 24px;
      transform: translateX(100%);
      z-index: 1000;
      transition: transform 0.25s;

      &.active {
        transform: translateX(0%);
      }
    }

    &-content {
      flex-grow: 1;
      overflow: auto;
    }

    &-controls {
      flex-shrink: 0;
    }
  }

</style>
