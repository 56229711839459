<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="#BF001F" xmlns="http://www.w3.org/2000/svg">
<path d="M19.8156 12.2264H32V18.9283H19.8156V32H12.2485V18.9283H0L0 12.2264H12.2485V0L19.8156 0V12.2264Z" />
</svg>
</template>

<script>
export default {
  name: 'AddEquipmentSvg'
}
</script>
