<template>
<svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.0747 32L123 60.4197L121.854 61.7075L114.738 55.3735V100.734H113.014V53.8387L91.0747 34.3083L69.1353 53.8387V100.734H67.4111V55.3735L60.2958 61.7075L59.1494 60.4197L91.0747 32Z" fill="#212121" stroke="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M77.8268 61.3652H103.402V63.0894H79.5509V100.734H77.8268V61.3652Z" fill="#313131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M87.6302 68.587L90.7912 63.0139L93.9521 68.587L91.3387 68.587L91.3387 94.223L93.9521 94.223L90.7912 99.7961L87.6302 94.223L90.2437 94.223L90.2437 68.587L87.6302 68.587Z" fill="#212121"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.7936 77.1366L33.1464 73.0725L36.4993 77.1366L33.7271 77.1366L33.7271 95.8313L36.4993 95.8313L33.1464 99.8954L29.7936 95.8313L32.5657 95.8313L32.5657 77.1366L29.7936 77.1366Z" fill="#BF001F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.1362 50.7288L56.2724 72.1738L55.1272 73.4627L50.1354 69.0275V100.733H48.4113V67.4956L32.1362 53.0352L15.8611 67.4956V100.733H14.1369V69.0275L9.14519 73.4627L8 72.1738L32.1362 50.7288Z" fill="#212121" stroke="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.0781 71.1357H41.3313V72.8599H23.8023V100.734H22.0781V71.1357Z" fill="#313131"/>
</svg>

</template>

<script>
export default {
  name: 'ArrowSvg'
}
</script>
