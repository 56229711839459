const GOOGLE_TAG_MANAGER_ID = 'GTM-KVSJ6Z4'

const initAnalyticsScripts = () => {
  if (!canUseAnalytics()) {
    return false
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    })
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    const dl = l !== 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', GOOGLE_TAG_MANAGER_ID)
}

const sendEvent = (data) => {
  if (!canUseAnalytics()) {
    return false
  }
  window.dataLayer.push(data)
}

const canUseAnalytics = () => {
  const cookieArray = `; ${document.cookie}`.split('; ')
  const cookie = cookieArray.find(item => item.startsWith('borlabs-cookie'))
  if (cookie) {
    const obj = JSON.parse(decodeURIComponent(cookie.split('=')[1]))
    return !!obj?.consents?.marketing
  } else {
    return false
  }
}

export default {
  initAnalyticsScripts,
  sendEvent,
  canUseAnalytics
}
