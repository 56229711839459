<template>
  <div class="range-wrapper">
    <div class="range-icon">
      <SvgPicker :name="`modal-${question}`" />
    </div>
    <div class="range">
      <input
        type="range"
        :value="currentValue"
        :min="min"
        :max="max"
        :step="step"
        v-on:input="updateValue"
        v-on:change="setAnswer"
      />
      <div class="scale">
        <span>{{ $n(scale.position1) }} {{ unit }}</span>
        <span>{{ $n(scale.position2) }}</span>
        <span>{{ $n(scale.position3) }}</span>
        <span>{{ $n(scale.position4) }}</span>
        <span>{{ $n(scale.position5) }} {{ unit }}</span>
      </div>
    </div>
    <label :for="`${category}-${question}-manualValue`" class="value-container">
      <input
        :id="`${category}-${question}-manualValue`"
        class="value"
        type="number"
        :value="currentValue"
        :min="min"
        :max="max"
        :step="step"
        v-on:change="setValue"
        @focus="$event.target.select()"
        />
      <span class="unit">{{ unit }}</span>
    </label>
  </div>
</template>

<script>
// import answerTypes from '../../../util/answerTypes.js'
import SvgPicker from '../../svg/SvgPicker'
import Analytics from '@/util/googleAnalytics'

export default {
  name: 'Range',
  components: {
    SvgPicker
  },
  props: {
    category: {
      type: String,
      default: ''
    },
    question: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    },
    step: {
      type: Number,
      default: 1
    },
    unit: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      currentValue: this.min,
      scale: {
        position1: this.min,
        position2: this.min + (this.max - this.min) * 0.25,
        position3: this.min + (this.max - this.min) * 0.5,
        position4: this.min + (this.max - this.min) * 0.75,
        position5: this.max
      }
    }
  },
  computed: {
    answer () {
      return this.$store.state.answers[this.category].data[this.question][0] || { id: 0, value: `${this.min}` }
    }
  },
  watch: {
    answer: function (data) {
      this.currentValue = parseFloat(data.value)
    }
  },
  mounted () {
    this.currentValue = parseFloat(this.answer.value)
  },
  methods: {
    updateValue (e) {
      this.currentValue = e.target.value
    },
    setValue (e) {
      let value = 0
      if (this.$props.step > 1) {
        value = parseInt(e.target.value)
      } else {
        value = parseFloat(e.target.value)
      }
      this.currentValue = value <= this.max && value >= this.min ? value : this.currentValue
      this.$forceUpdate()
      this.setAnswer()
    },
    getAnswerName (id) {
      if (id === 8) return 'Hubhöhe:'
      if (id === 9) return 'Tragkraft:'
      if (id === 10) return 'Tiefste Durchfahrtshöhe:'
      if (id === 13) return 'Gabelzinken:'
      if (id === 16) return 'Arbeitsgangbreite:'
      // if (id === 17) return 'Ausstattung Blei-Säure-Batterie'
    },
    setAnswer () {
      const data = {
        category: this.category,
        question: this.question,
        answer: [{ id: this.id, value: `${this.currentValue} ${this.unit}` }]
      }
      Analytics.sendEvent({
        event: 'Answer selected',
        data: `${this.getAnswerName(this.id)} ${this.currentValue} ${this.unit}`
      })
      this.$store.dispatch('setAnswer', data)
    }
  }
}
</script>

<style lang="scss" scoped>
$track-color: #6B6A6B !default;
$thumb-color: #BF001F !default;

$thumb-radius: 16px !default;
$thumb-height: 32px !default;
$thumb-width: 32px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 0 !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 2px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 0 !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
}

@mixin thumb {
  position: relative;
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
  cursor: pointer;
  z-index: 100;
}

.range-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding-right: 30px;

  @media (max-width: 991px) {
    padding-right: 16px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding-right: 0;
  }
}

.range {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 767px) {
    height: 60px;
  }
}

.range-icon {
  flex-shrink: 0;
  width: 80px;
  margin-right: 24px;

  @media (max-width: 991px) {
    display: none;
  }
}

.scale {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-top: 8px;

  span {
    display: block;
    position: absolute;
    font-size: 16px;
    color: #cacaca;
    text-transform: lowercase;

    &:before {
      content: '';
      border-left: 2px solid #6b6a6b;
      position: absolute;
      top: -32px;
      left: 50%;
      width: 0;
      height: 12px;
    }

    &:nth-child(1) {
      left: 0;

      &:before {
        left: 0;
      }
    }

    &:nth-child(2) {
      left: 26%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      left: 50%;
      transform: translateX(-50%)
    }

    &:nth-child(4) {
      left: 73%;
      transform: translateX(-40%)
    }

    &:nth-child(5) {
      right: 0;

      &:before {
        right: 0;
        left: unset;
      }
    }

    @media (max-width: 767px) {
      font-size: 0;
      line-height: 14px;

      &:first-child,
      &:last-child {
        font-size: 12px;
      }

      &:before {
        top: -29px;
      }
    }
  }
}

.info {
  flex-shrink: 0;
  position: relative;
  margin-left: 36px;
}

.value-container {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 51px;
  margin-left: 32px;
  border: 1px solid #CACACA;
  border-radius: 3px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #000000;

  @media (max-width: 767px) {
    width: 100%;
    height: 48px;
    margin-left: 0;
    margin-top: 16px;
  }
}

.value {
  max-width: 55%;
  border: none;
  text-align: center;
  padding: 0;
  color: #666;
  font-weight: 500;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    position: absolute;
    text-align: right;
    top: 50%;
    right: 51%;
    transform: translateY(-50%);
    width: 45%;
    text-align: right;
  }
}

.unit {
  align-self: center;
  color: #666;
  text-transform: lowercase;

    @media (max-width: 767px) {
    position: absolute;
    text-align: right;
    top: 50%;
    left: 51%;
    transform: translateY(-50%);
    text-align: right;
  }
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  padding-top: $thumb-height / 2 + 8px;
  padding-bottom: $thumb-height / 2;
  width: $track-width;
  height: $track-height;
  box-sizing: content-box;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
  }

  &::-moz-range-track {
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}
</style>
