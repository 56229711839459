import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

import i18n from '../translations/translations'
import { ContactFormModel } from '../core/models/contactModel'
import { AnswersModel } from '../core/models/answersModel'

Vue.use(Vuex, VueAxios, axios)

const MAX_STEPS = 9

export default new Vuex.Store({
  state: {
    baseUrl: process.env.PUBLIC_PATH || process.env.VUE_APP_PUBLIC_PATH,
    locale: 'de',
    referenceNumber: null,
    activeStep: 1,
    previousStep: null,
    recommendationsExpanded: false,
    recommendationsLoading: false,
    showConf: false,
    equipment: [],
    equipmentPrice: 0,
    selectedModel: {},
    totalPrice: 0,
    contactSent: false,
    contactFormTitle: '',
    modal: {
      opened: false,
      hasImage: false,
      data: ''
    },
    recommendations: [],
    answers: new AnswersModel(),
    contactForm: new ContactFormModel(),
    equipmentData: [
      {
        name: 'lightPackage',
        price: 17.81
      },
      {
        name: 'comfortPackage',
        price: 65.26
      },
      {
        name: 'sightPackage',
        price: 8.95
      },
      {
        name: 'securityPackage',
        price: 38.95
      }
    ]
  },
  getters: {
    // Here we will create a getter
  },
  mutations: {
    setStep (state, step) {
      state.recommendationsExpanded = false
      state.activeStep = step
    },
    checkPrevStep (state) {
      if (state.previousStep) {
        this.commit('setStep', state.previousStep)
        state.previousStep = null
      } else {
        this.commit('prevStep')
      }
    },
    prevStep (state) {
      state.activeStep--
    },
    nextStep (state) {
      state.activeStep++
    },
    setAnswer (state, data) {
      state.answers[data.category].data[data.question] = [...data.answer]
      const answers = Object.values(state.answers[data.category].data).filter(item => item.length > 0)
      state.answers[data.category].selected = answers.length > 0
      this.commit('getRecommendations')
    },
    removeAnswer (state, data) {
      state.answers[data.category].data[data.question] = []
      const answers = Object.values(state.answers[data.category].data).filter(item => item.length > 0)
      state.answers[data.category].selected = answers.length > 0
      this.commit('getRecommendations')
    },
    toggleEquipment (state, data) {
      for (const item of state.equipment) {
        if (item.name === data.name) {
          state.equipment = state.equipment.filter(item => item.name !== data.name)
          state.equipmentPrice -= data.price
          this.commit('setTotalPrice')
          return false
        }
      }
      state.equipment.push(data)
      state.equipmentPrice += data.price
      this.commit('setTotalPrice')
    },
    setTotalPrice (state) {
      state.totalPrice = (+state.selectedModel?.price + state.equipmentPrice).toFixed(0)
    },
    getRecommendations (state) {
      state.recommendationsLoading = true
      state.recommendations = []
      const data = []
      for (const category in state.answers) {
        for (const answer in state.answers[category].data) {
          state.answers[category].data[answer].forEach(item => {
            data.push(JSON.parse(`{ "answer": ${item.id}, "value": ${parseFloat(item.value) || 0} }`))
          })
        }
      }
      axios.post(`${state.baseUrl}/api/v1/filter`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        state.recommendations = [...response.data]
        state.selectedModel = Object.assign({}, response.data[0])
        this.commit('setTotalPrice')
        state.equipment = []
        state.equipmentPrice = 0
        state.recommendationsLoading = false
      })
    },
    getReferenceNumber (state) {
      axios.get(`${state.baseUrl}/api/v1/contact/referenceNumber`).then(res => {
        state.referenceNumber = res.data || null
      })
    },
    sendContactForm (state, data) {
      axios.post(`${state.baseUrl}/api/v1/contact`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        state.contactSent = res.status === 201
      })
    },
    toggleRecommendations (state, data) {
      state.recommendationsExpanded = data || !state.recommendationsExpanded
    },
    setLocale (state, data) {
      i18n.locale = data
      state.locale = data
    },
    selectModel (state, data) {
      state.previousStep = data.activeStep
      state.selectedModel = Object.assign({}, data.model)
      state.recommendationsExpanded = false
      state.equipment = []
      state.equipmentPrice = 0
      this.commit('setTotalPrice')
      this.commit('setStep', 9)
    },
    restartConfigurator (state) {
      state.contactSent = false
      state.referenceNumber = null
      state.contactForm = new ContactFormModel()
      state.answers = new AnswersModel()
      this.commit('setStep', 1)
    },
    openModal (state, data) {
      state.modal.name = data.name
      state.modal.hasImage = data.hasImage
      state.modal.opened = true
    },
    closeModal (state) {
      state.modal.opened = false
    },
    toggleConf (state, data) {
      state.showConf = data || !state.showConf
    },
    setContactFormTitle (state, title) {
      state.contactFormTitle = title
    }
  },
  actions: {
    setStep (context, step) {
      context.commit('setStep', step)
    },
    checkPrevStep (context) {
      context.commit('checkPrevStep')
    },
    prevStep (context) {
      if (context.state.activeStep > 1) {
        context.commit('prevStep')
      }
    },
    nextStep (context) {
      if (context.state.activeStep < MAX_STEPS) {
        context.commit('nextStep')
      }
    },
    setAnswer (context, data) {
      context.commit('setAnswer', data)
    },
    removeAnswer (context, data) {
      context.commit('removeAnswer', data)
    },
    toggleEquipment (context, data) {
      context.commit('toggleEquipment', data)
    },
    setTotalPrice (context) {
      context.commit('setTotalPrice')
    },
    setContactFormTitle (context, title) {
      context.commit('setContactFormTitle', title)
    },
    getReferenceNumber (context) {
      context.commit('getReferenceNumber')
    },
    sendContactForm (context, data) {
      context.commit('sendContactForm', data)
    },
    getRecommendations (context) {
      context.commit('getRecommendations')
    },
    toggleRecommendations (context, data) {
      context.commit('toggleRecommendations', data)
    },
    setLocale (context, data) {
      context.commit('setLocale', data)
    },
    selectModel (context, data) {
      context.commit('selectModel', data)
    },
    restartConfigurator (context) {
      context.commit('restartConfigurator')
    },
    openModal (context, data) {
      context.commit('openModal', data)
    },
    closeModal (context) {
      context.commit('closeModal')
    },
    toggleConf (context, data) {
      context.commit('toggleConf', data)
    }
  }
})
