<template>
  <div class="thank-you">
    <div>
      <h2 class="thank-you-title">{{ $t('contact.thankYou.title') }}</h2>
      <h3 class="thank-you-subtitle">{{ $t('contact.thankYou.subtitle') }}</h3>
      <p class="thank-you-text">{{ $t('contact.thankYou.text') }} <a href="https://www.gruma.de/akademie/onlineschulungen/?utm_source=konfigurator&utm_medium=website&utm_campaign=staplerkonfigurator" target="_blank">{{ $t('contact.thankYou.link') }}</a></p>
    </div>
    <div class="thank-you-controls">
      <button class="btn btn--big" @click="restartConfigurator">{{ $t('contact.thankYou.backConf') }}</button>
      <a class="btn btn--primary btn--big" href="https://www.gruma.de/?utm_source=konfigurator&utm_medium=website&utm_campaign=staplerkonfigurator" target="_black">{{ $t('contact.thankYou.backSite') }}</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThankYou',
  methods: {
    restartConfigurator () {
      this.$store.dispatch('restartConfigurator')
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
  .thank-you {
    flex-grow: 1;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-title {
      margin-bottom: 24px;
      font-weight: 900;
      font-size: 64px;

      @media (max-width: 767px) {
        font-size: 22px;
        font-weight: 700;
      }
    }

    &-subtitle {
      margin-bottom: 64px;
      font-weight: 900;
      font-size: 24px;

      @media (max-width: 767px) {
        font-size: 18px;
        font-weight: 500;
      }
    }

    &-text {
      max-width: 850px;
      margin-bottom: 78px;
      font-size: 22px;
      font-weight: 400;
      text-transform: none;

      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &-controls {
      @media (max-width: 767px) {
        .btn {
          width: 100%;
        }
      }
    }

    .btn--primary {
      margin-left: 50px;

      @media (max-width: 767px) {
        margin-left: 0px;
        margin-top: 12px;
      }
    }
  }
</style>
