<template>
  <section class="navigation" ref="navigation">
    <a href="javascript:;" class="nav-btn" :class="{ 'active' : activeStep >= 1 }" @click="setStep($event, 1)"><span>{{ $t("environmentLocation.description") }}</span></a>
    <a href="javascript:;" class="nav-btn mobile" :class="{ 'active' : activeStep >= 2 }" @click="setStep($event, 2)"><span></span></a>
    <a href="javascript:;" class="nav-btn" :class="{ 'active' : activeStep >= 3 }" @click="setStep($event, 3)"><span>{{ $t("operationLift.description") }}</span></a>
    <a href="javascript:;" class="nav-btn mobile" :class="{ 'active' : activeStep >= 4 }" @click="setStep($event, 4)"><span></span></a>
    <a href="javascript:;" class="nav-btn mobile" :class="{ 'active' : activeStep >= 5 }" @click="setStep($event, 5)"><span></span></a>
    <a href="javascript:;" class="nav-btn mobile" :class="{ 'active' : activeStep >= 6 }" @click="setStep($event, 6)"><span></span></a>
    <a href="javascript:;" class="nav-btn" :class="{ 'active' : activeStep >= 7 }" @click="setStep($event, 7)"><span>{{ $t("deliveryForks.description") }}</span></a>
    <a href="javascript:;" class="nav-btn mobile" :class="{ 'active' : activeStep >= 8 }" @click="setStep($event, 8)"><span></span></a>
    <a href="javascript:;" class="nav-btn" :class="{ 'active' : activeStep >= 9 }" @click="setStep($event, 9)"><span>{{ $t("summary.name") }}</span></a>
  </section>
</template>

<script>

export default {
  name: 'Navigation',
  computed: {
    activeStep () {
      return this.$store.state.activeStep
    }
  },
  methods: {
    setStep (event, step) {
      const nav = this.$refs.navigation
      const el = event.target
      const scrollX = el.offsetLeft - ((nav.offsetWidth - el.offsetWidth) / 2)
      this.$refs.navigation.scrollTo({
        top: 0,
        left: scrollX,
        behavior: 'smooth'
      })
      this.$store.dispatch('setStep', step)
    }
  }
}
</script>

<style scoped lang="scss">
  .navigation {
    grid-area: navigation;
    display: flex;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 36px;
    margin-top: 36px;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 1600px) {
      margin-bottom: 24px;
      margin-left: 24px;
    }

    @media (max-width: 1250px) {
      margin-left: 24px;
      margin-bottom: 16px;
      margin-top: 0px;
      padding: 12px 0;
      overflow: auto;
    }

    @media (max-width: 991px) {
      margin-left: 0;
      margin-right: 0;
      padding: 12px 16px;
      overflow: auto;
    }

    @media (max-width: 767px) {
      margin-left: 0;
      margin-bottom: 24px;
      margin-top: 0;
      padding: 12px 8px;
      box-shadow: 0px 4px 8px rgba(220, 220, 220, 0.25);
    }
  }

  .navigation a {
    @media (max-width: 1250px) {

    }

    @media (max-width: 991px) {
      padding: 12px 16px;

    }
  }

  .nav-btn {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0px;
    color: #666666;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    transition: all 0.25s;
    border-bottom: 5px solid rgb(230, 228, 228);

    @media (max-width: 1600px) {

      font-size: 18px;
      line-height: 18px;
      margin-bottom: 8px;;
    }

    @media (max-width: 1250px) {

    }

    @media (max-width: 767px) {
      padding-bottom: 0;
      font-size: 14px;
      line-height: 1.2;
      border-bottom: none;
    }

    &.active {
      color: #BF001F;
      border-bottom: 5px solid #BF001F;

      .icon {
        transform: translateX(0);
        opacity: 1;
        transition-delay: 0.25s;
      }
    }

    span {
      pointer-events: none;
      margin-bottom: 15px;

      @media (max-width: 991px) {
        margin-bottom: 0px;
    }
    }
  }

  .icon {
    display: inline-block;
    margin-left: 8px;
    vertical-align: top;
    opacity: 0;
    transform: translateX(-8px);

    @media (max-width: 1250px) {
      display: none;
    }
  }

  a.nav-btn {
    flex: 1;

  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }
</style>
