<template>
  <section>
    <Recommendations />
    <Summary v-if="selectedModel.hasOwnProperty('modelName')" />
    <AdditionalEquipment v-if="selectedModel.hasOwnProperty('modelName')" />
    <NoModel v-else />
    <Controls />

  </section>
</template>

<script>
import Recommendations from './Recommendations.vue'
import Summary from './questions/Summary.vue'
import AdditionalEquipment from './AdditionalEquipment.vue'
import NoModel from './NoModel.vue'
import Controls from './Controls'

export default {
  name: 'Questions',
  components: {
    Recommendations,
    Summary,
    AdditionalEquipment,
    NoModel,
    Controls
  },
  computed: {
    selectedModel () {
      return this.$store.state.selectedModel
    },
    activeStep () {
      return this.$store.state.activeStep
    },
    showConf () {
      return this.$store.state.showConf
    }
  },
  methods: {
    openConf () {
      this.$store.dispatch('toggleConf', true)
    }
  },
  mounted () {
    this.$store.dispatch('getReferenceNumber')
  }
}
</script>

<style scoped lang="scss">
  .questions {
    grid-area: questions;
    position: relative;
    margin-left: 80px;
    margin-right: 20px;
    margin-bottom: 24px;
    padding: 0 10px 20px 0;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 767px) {
      margin: 0;
      padding: 10px 16px;
    }
  }

  .question-enter-active {
    animation: appear .5s ease-out;
  }

  .question-leave-active {
    animation: appear .5s ease-in reverse;
  }

  .mobile-control {
    display: none;

    @media (max-width: 767px) {
      display: block;
      position: fixed;
      top: 132px;
      right: 24px;
      width: 52px;
      height: 52px;
      padding: 0;
      border: none;
      border-radius: 50%;
      background-color: #BF001F;
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.3);
      color: #fff;
      font-size: 10px;
      text-transform: uppercase;
      white-space: normal;
      cursor: pointer;
    }
  }

  @keyframes appear {
    0% {
      position: absolute;
      left: 100%;
    }
    100% {
      position: absolute;
      left: 0%;
    }
  }
</style>
