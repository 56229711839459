<template>
  <div class="input-wrapper" :class="{ active: activeState, error: errors.length }">
    <label class="input-label">{{ label }}{{ required ? '*' : '' }}</label>
    <input
      class="input"
      autocomplete="off"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="activeValue"
      :required="required"
      @change="onChange"
    />
    <div class="input-errors" v-if="errors.length">
      <span v-for="error in errors" :key="error">{{ $t(`error.${error}`) }}</span>
    </div>
  </div>
</template>

<script>
const TYPES = [
  'text',
  'password',
  'email',
  'number',
  'url',
  'tel',
  'search',
  'hidden'
]

export default {
  name: 'Input',
  props: {
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: '',
      validator (value) {
        const isValid = TYPES.includes(value)
        if (!isValid) {
          // eslint-disable-next-line
          console.warn(`allowed types are ${TYPES}`)
        }
        return isValid
      }
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    pattern: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      activeState: false,
      activeValue: this.checkValue(this.$props.value),
      hasError: false,
      errors: []
    }
  },
  watch: {
    value: function (data) {
      this.activeValue = this.checkValue(data)
    }
  },
  methods: {
    onChange (e) {
      const value = e.target.value
      if (value !== '') {
        if (e.target.type === 'number' && !parseFloat(Math.abs(value))) {
          return false
        }
        this.validate(value)
        this.activeValue = value
        this.$emit('update', value)
      }
    },
    checkValue (value) {
      switch (this.$props.type) {
        case 'text':
          return value || ''
        case 'number':
          return parseFloat(value) || ''
        case 'email':
          return value || ''
        case 'tel':
          return value || ''
        default: {
          return value || ''
        }
      }
    },
    validate (data) {
      const value = data || this.activeValue
      this.errors = []
      if (this.$props.required && value.trim() === '') {
        this.errors.push('required')
      }
      switch (this.$props.type) {
        case 'tel':
          if (!value.match(/\+?([0-9]){6,15}/)) {
            this.errors.push('phoneFormat')
          }
          break
        case 'email':
          // const emailRegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          if (!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            this.errors.push('emailFormat')
          }
          break
        default: {
          break
        }
      }
      if (this.errors.length > 0) {
        this.$emit('error')
      }
    }
  }
}
</script>
