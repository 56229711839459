import Vue from 'vue'
import VueI18n from 'vue-i18n'
import de from './de'
import en from './en'

Vue.use(VueI18n)

const translations = {
  en,
  de
}
export default new VueI18n({
  locale: 'de',
  // locale: store.state.locale,
  messages: translations
})
